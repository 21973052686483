import React, { useEffect } from "react";

const ProjectPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", disableRightClick);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  return (
    <div className="md:p-8 px-8 overflow-x-hidden">
      <div className="md:col-span-2 mt-12 ml-4 block md:hidden">
        <h1 className="text-2xl text-gray-600 font-semibold">{props.title}</h1>
        <p className="my-2 text-sm text-gray-600 max-w-2xl">
          {props.description}
        </p>
        {props.credits && (
          <div className="my-4 grid grid-cols-2 gap-0">
            <p className="text-xs">
              Model:{" "}
              <span className="font-semibold">{props.credits.model}</span>
            </p>
            {props.credits.mua && (
              <p className="text-xs">
                Hair & Makeup Artist:{" "}
                <span className="font-semibold">{props.credits.mua}</span>
              </p>
            )}
            <p className="text-xs">
              Stylist:{" "}
              <span className="font-semibold">{props.credits.stylist}</span>
            </p>
            <p className="text-xs">
              Photographer:{" "}
              <span className="font-semibold">
                {props.credits.photographer}
              </span>
            </p>
          </div>
        )}
      </div>
      <div className="md:grid md:grid-cols-7">
        <div className="md:col-span-5">
          <div className="md:grid md:grid-cols-2 gap-4 mt-12 content-center place-items-start">
            {props.images.map((pi, index) =>
              props.landscapeImages && props.landscapeImages.includes(index) ? (
                <img
                  key={index}
                  className="md:w-full md:h-[620px] md:object-cover object-contain mx-auto md:mt-0 mt-8 col-span-2"
                  src={pi}
                  alt={`Image ${index}`}
                />
              ) : (
                <img
                  key={index}
                  className="md:w-full md:h-[620px] md:object-cover object-contain mx-auto md:mt-0 mt-8 col-span-1"
                  src={pi}
                  alt={`Image ${index}`}
                />
              )
            )}
          </div>
        </div>
        <div className="md:col-span-2 mt-12 ml-4 md:block hidden">
          <h1 className="text-2xl text-gray-600 font-semibold">
            {props.title}
          </h1>
          <p className="my-2 text-sm text-gray-600 max-w-2xl">
            {props.description}
          </p>
          {props.credits && (
            <div className="my-4 grid grid-cols-2 gap-0">
              <p className="text-xs">
                Model:{" "}
                <span className="font-semibold">{props.credits.model}</span>
              </p>
              {props.credits.mua && (
                <p className="text-xs">
                  Hair & Makeup Artist:{" "}
                  <span className="font-semibold">{props.credits.mua}</span>
                </p>
              )}

              <p className="text-xs">
                Stylist:{" "}
                <span className="font-semibold">{props.credits.stylist}</span>
              </p>
              <p className="text-xs">
                Photographer:{" "}
                <span className="font-semibold">
                  {props.credits.photographer}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
