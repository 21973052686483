import React from "react";
import { coverImages } from "../Utils/utilsHelper";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", disableRightClick);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);
  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 md:gap-y-24 gap-y-8 md:px-20 px-6 py-10">
        {coverImages.map((ci) => {
          return (
            <div className="hover:opacity-25">
              <Link to={ci.path}>
                <img
                  className="w-[350px] md:h-[450px] h-[250px] object-cover  shadow"
                  src={ci.img}
                />
              </Link>
            </div>
          );
        })}
      </div>

      <p className="mt-8 font-light text-center">All rights reserved. ©2023</p>
      <a
        href="https://portfolio-b9fec.web.app/"
        target="_blank"
        className="underline"
        rel="noreferrer"
      >
        <p className="text-sm mb-2 font-light text-center">
          Created by Prabhansh Jain
        </p>
      </a>
    </div>
  );
};

export default Home;
