import aw from "../assets/cover/alexander-wang-cover.jpg"
import ashi from "../assets/cover/ashi-cover.jpg"
import bg from "../assets/cover/bhangarh-cover.jpg"
import jb from "../assets/cover/jissa-beach-cover.jpg"
import jl1 from "../assets/cover/jissa-look-1-cover.jpg"
import jl2 from "../assets/cover/jissa-look-2-cover.jpg"
import kow from "../assets/cover/kow-cover.webp"
import retro from "../assets/cover/retro-cover.png"
import dhp from "../assets/cover/desi-house-party-cover.jpg"
import ele from "../assets/cover/elegante-cover.jpg"
import gvig from "../assets/cover/gautam-vig-cover.jpg"
import jl3 from "../assets/cover/jl3-cover.jpg"
import linen from "../assets/cover/live-linen-cover.jpg"
import pdress from "../assets/cover/power-dressing-cover.png"
import fishy from "../assets/cover/so-fishy-cover.jpg"
import timeless from "../assets/cover/timeless-classics-cover.jpg"
import party from "../assets/cover/party-cover.jpg"
import ggxrv from "../assets/cover/ggxrv-cover.jpg"
import rsvp from "../assets/cover/rsvp-cover.jpg"
import nykd from "../assets/cover/nykd-cover.jpg"
import anusha1 from "../assets/cover/anusha_look1_cover.jpg"
import anusha2 from "../assets/cover/anusha_look2_cover.jpg"
import autumnWinter from "../assets/cover/autumn_winter_cover.jpg"
import masabaPuma from "../assets/cover/masaba_puma_cover.jpg"
import nfCampaign from "../assets/cover/nf_campaign_cover.jpg"
import shreya1 from "../assets/cover/shreya_chaudhry_cover.jpg"
import aw1 from "../assets/alexander-wang/1.1.jpg"
import aw2 from "../assets/alexander-wang/1.3.jpg"
import aw3 from "../assets/alexander-wang/1.4.jpg"
import aw4 from "../assets/alexander-wang/2.jpg"
import aw5 from "../assets/alexander-wang/3.jpg"
import a1 from "../assets/ashi/338934202_202579862489595_6574963855894655574_n.jpg"
import a2 from "../assets/ashi/338943999_702169635030715_8313076819225306012_n.jpg"
import a3 from "../assets/ashi/339272729_226253023397075_5384168288656088503_n.jpg"
import a4 from "../assets/ashi/339361421_921818642354963_6326950841538014149_n.jpg"
import a5 from "../assets/ashi/343568493_149662647865812_3511772304559546625_n.jpg"
import a6 from "../assets/ashi/343669417_1586077808540987_5862922289991943819_n.jpg"
import bg1 from "../assets/bhangarh/TME_0101 (1).jpg"
import bg2 from "../assets/bhangarh/TME_0123 (1).jpg"
import bg3 from "../assets/bhangarh/TME_0133 (1).jpg"
import bg4 from "../assets/bhangarh/TME_0156 (1).jpg"
import bg5 from "../assets/bhangarh/TME_0165 (1).jpg"
import ja1 from "../assets/jissa-look-1/351077174_1292562228332318_6745822128497834014_n.jpg"
import ja2 from "../assets/jissa-look-1/351208150_1418131799031345_1966131445011629370_n.jpg"
import ja3 from "../assets/jissa-look-1/351425142_1369411890274074_8077185488622178920_n.jpg"
import ja4 from "../assets/jissa-look-1/352109183_1295986504339321_2794041020049690481_n.jpg"
import jb1 from "../assets/jissa-look-2/351763875_961710535168966_2488310417315648601_n.jpg"
import jb2 from "../assets/jissa-look-2/352112687_2329569933893221_6725585872600301170_n.jpg"
import jb3 from "../assets/jissa-look-2/352196075_5490352771067037_6557657781897129978_n.jpg"
import jc1 from "../assets/jissa-look-3/352204613_771063994523841_3825555870531546316_n.jpg"
import jc2 from "../assets/jissa-look-3/352239558_1929888010716218_8237368052617244469_n.jpg"
import jc3 from "../assets/jissa-look-3/352312817_567779162196999_2117582943211253624_n.jpg"
import jb4 from "../assets/jissa-look-2/352236323_631195908678221_3756484048909825160_n.jpg"
import jbe1 from "../assets/jissa-beach/341156469_6826640174030388_5623703628163688373_n.jpg"
import jbe2 from "../assets/jissa-beach/341172002_1191138068222369_8101351233334284336_n.jpg"
import jbe3 from "../assets/jissa-beach/341571072_510048311335497_3996297473482465336_n.jpg"
import k1 from "../assets/kow/1.1.webp"
import k2 from "../assets/kow/10.1.webp"
import k3 from "../assets/kow/5.2.webp"
import k4 from "../assets/kow/6.2.webp"
import k5 from "../assets/kow/7.1.webp"
import k6 from "../assets/kow/8.1.webp"
import k7 from "../assets/kow/9.1.jpg"
import r1 from "../assets/retro/0e3a29139546329.62319c23f3b59.png"
import r2 from "../assets/retro/1e7844139546329.62319c21d624d.png"
import r3 from "../assets/retro/257488139546329.62319c21d57c0.png"
import r4 from "../assets/retro/b52d18139546329.62319c1f38612.png"
import dhp1 from "../assets/desi-house-party/DSC_8699.jpg"
import dhp2 from "../assets/desi-house-party/DSC_8733.jpg"
import dhp3 from "../assets/desi-house-party/DSC_8814.jpg"
import dhp4 from "../assets/desi-house-party/DSC_8870.jpg"
import ele1 from "../assets/elegante/2.jpg"
import ele2 from "../assets/elegante/3.jpg"
import ele3 from "../assets/elegante/4.jpg"
import ele4 from "../assets/elegante/5.jpg"
import ele5 from "../assets/elegante/IMG_0017.jpg"
import gvig1 from "../assets/gautam-vig/771a503ea5174413abf07d0caad7179a_318747422_220284470422571_7482182921291528702_n.jpg"
import gvig2 from "../assets/gautam-vig/7D3A6764.jpg"
import gvig3 from "../assets/gautam-vig/7D3A6767.jpg"
import gvig4 from "../assets/gautam-vig/IMG-20221206-WA0008.jpg"
import gvig5 from "../assets/gautam-vig/IMG-20221206-WA0009.jpg"
import gvig6 from "../assets/gautam-vig/IMG-20221206-WA0010.jpg"
import linen1 from "../assets/live-linen/236496948_183587807043971_4895891211215267556_n.jpg"
import linen2 from "../assets/live-linen/240952166_383643813220536_7758301882219376770_n.jpg"
import linen3 from "../assets/live-linen/241379110_1264236207340104_3566081096041441018_n.jpg"
import linen4 from "../assets/live-linen/242210151_993550338091649_2515747562643497361_n.jpg"
import linen5 from "../assets/live-linen/244639479_241287961237621_1001760081109267948_n.jpg"
import linen6 from "../assets/live-linen/245234673_291394466156589_2829083675418299704_n.jpg"
import linen7 from "../assets/live-linen/253779436_123692160063474_1910606187373098676_n.jpg"
import pd1 from "../assets/power-dressing/0d5cf8139548109.6231a3e1442e1.png"
import pd2 from "../assets/power-dressing/1b3acd139548109.6231a3e340fb0.png"
import pd3 from "../assets/power-dressing/64eb39139548109.6231a3e033208.png"
import pd4 from "../assets/power-dressing/d478be139548109.6231a3e45753f.png"
import fishy1 from "../assets/so-fishy/1.jpg"
import fishy2 from "../assets/so-fishy/IMG_5468.jpg"
import fishy3 from "../assets/so-fishy/IMG_5498.jpg"
import fishy4 from "../assets/so-fishy/IMG_5600.jpg"
import fishy5 from "../assets/so-fishy/IMG_5634.jpg"
import fishy6 from "../assets/so-fishy/IMG_5660.jpg"
import fishy7 from "../assets/so-fishy/IMG_5680.jpg"
import fishy8 from "../assets/so-fishy/IMG_5798.jpg"
import fishy9 from "../assets/so-fishy/IMG_5817.jpg"
import fishy10 from "../assets/so-fishy/IMG_5850.jpg"
import tc1 from "../assets/timeless-classics/TME_0915.jpg"
import tc2 from "../assets/timeless-classics/TME_0962.jpg"
import tc3 from "../assets/timeless-classics/TME_0995.jpg"
import tc4 from "../assets/timeless-classics/TME_1025.jpg"
import tc5 from "../assets/timeless-classics/TME_1034.jpg"
import gg1 from "../assets/ggxrv/1.jpg"
import gg2 from "../assets/ggxrv/2.jpg"
import gg3 from "../assets/ggxrv/3.jpg"
import gg4 from "../assets/ggxrv/4.jpg"
import gg5 from "../assets/ggxrv/5.jpg"
import gg6 from "../assets/ggxrv/6.jpg"
import gg7 from "../assets/ggxrv/7.jpg"
import gg8 from "../assets/ggxrv/8.jpg"
import gg9 from "../assets/ggxrv/9.jpg"
import gg10 from "../assets/ggxrv/10.jpg"
import gg11 from "../assets/ggxrv/11.jpg"
import gg12 from "../assets/ggxrv/12.jpg"
import pc1 from "../assets/party-campaign/1.jpg";
import pc2 from "../assets/party-campaign/2.jpg";
import pc3 from "../assets/party-campaign/3.jpg";
import pc4 from "../assets/party-campaign/4.jpg";
import pc5 from "../assets/party-campaign/5.jpg";
import pc6 from "../assets/party-campaign/6.jpg";
import pc7 from "../assets/party-campaign/7.jpg";
import pc8 from "../assets/party-campaign/8.jpg";
import pc9 from "../assets/party-campaign/9.jpg";
import pc10 from "../assets/party-campaign/10.jpg";
import rsvp1 from "../assets/rsvp/1.jpg";
import rsvp2 from "../assets/rsvp/2.jpg";
import rsvp3 from "../assets/rsvp/3.jpg";
import rsvp4 from "../assets/rsvp/4.jpg";
import rsvp5 from "../assets/rsvp/5.jpg";
import rsvp7 from "../assets/rsvp/7.jpg";
import rsvp8 from "../assets/rsvp/8.jpg";
import rsvp9 from "../assets/rsvp/9.jpg";
import rsvp10 from "../assets/rsvp/10.jpg";
import rsvp11 from "../assets/rsvp/11.jpg";
import rsvp12 from "../assets/rsvp/12.jpg";
import rsvp13 from "../assets/rsvp/13.jpg";
import rsvp14 from "../assets/rsvp/14.jpg";
import rsvp15 from "../assets/rsvp/15.jpg";
import rsvp16 from "../assets/rsvp/16.jpg";
import nykd1 from "../assets/nykd/1.jpg";
import nykd2 from "../assets/nykd/2.jpg";
import nykd3 from "../assets/nykd/3.jpg";
import nykd4 from "../assets/nykd/4.jpg";
import nykd5 from "../assets/nykd/5.jpg";
import nykd6 from "../assets/nykd/6.jpg";
import nykd7 from "../assets/nykd/7.jpg";
import nykd8 from "../assets/nykd/8.jpg";
import nykd9 from "../assets/nykd/9.jpg";
import nykd10 from "../assets/nykd/10.jpg";
import nykd11 from "../assets/nykd/11.jpg";
import nykd12 from "../assets/nykd/12.jpg";
import nykd13 from "../assets/nykd/13.jpg";
import nykd14 from "../assets/nykd/14.jpg";
import anusha1_1 from "../assets/anusha_look1/1.jpg"
import anusha1_2 from "../assets/anusha_look1/2.jpg"
import anusha1_3 from "../assets/anusha_look1/3.jpg"
import anusha1_4 from "../assets/anusha_look1/4.jpg"
import anusha1_5 from "../assets/anusha_look1/5.jpg"
import anusha1_6 from "../assets/anusha_look1/6.jpg"
import anusha1_7 from "../assets/anusha_look1/7.jpg"
import anusha2_1 from "../assets/anusha_look2/1.jpg"
import anusha2_2 from "../assets/anusha_look2/2.jpg"
import anusha2_3 from "../assets/anusha_look2/3.jpg"
import anusha2_4 from "../assets/anusha_look2/4.jpg"
import anusha2_5 from "../assets/anusha_look2/5.jpg"
import anusha2_6 from "../assets/anusha_look2/6.jpg"
import anusha2_7 from "../assets/anusha_look2/7.jpg"
import anusha2_8 from "../assets/anusha_look2/8.jpg"
import autumnWinter1 from "../assets/autumn_winter_2023/1.jpg"
import autumnWinter2 from "../assets/autumn_winter_2023/2.jpg"
import autumnWinter3 from "../assets/autumn_winter_2023/3.jpg"
import autumnWinter4 from "../assets/autumn_winter_2023/4.jpg"
import autumnWinter5 from "../assets/autumn_winter_2023/5.jpg"
import masabaPuma1 from "../assets/masaba_puma/1.jpg"
import masabaPuma2 from "../assets/masaba_puma/2.jpg"
import masabaPuma3 from "../assets/masaba_puma/3.jpg"
import masabaPuma4 from "../assets/masaba_puma/4.jpg"
import masabaPuma5 from "../assets/masaba_puma/5.jpg"
import nf1 from "../assets/nf_campaign/1.jpg"
import nf2 from "../assets/nf_campaign/2.jpg"
import nf3 from "../assets/nf_campaign/3.jpg"
import nf4 from "../assets/nf_campaign/4.jpg"
import nf5 from "../assets/nf_campaign/5.jpg"
import nf6 from "../assets/nf_campaign/6.jpg"
import nf7 from "../assets/nf_campaign/7.jpg"
import nf8 from "../assets/nf_campaign/8.jpg"
import nf9 from "../assets/nf_campaign/9.jpg"
import nf10 from "../assets/nf_campaign/10.jpg"
import nf11 from "../assets/nf_campaign/11.jpg"
import shreya1_1 from "../assets/shreya_chaudhry/1.jpg"
import shreya1_2 from "../assets/shreya_chaudhry/2.jpg"
import shreya1_3 from "../assets/shreya_chaudhry/3.jpg"
import shreya1_4 from "../assets/shreya_chaudhry/4.jpg"
import shreya1_5 from "../assets/shreya_chaudhry/5.jpg"
import shreya1_6 from "../assets/shreya_chaudhry/6.jpg"
import shreya1_7 from "../assets/shreya_chaudhry/7.jpg"
import shreya1_8 from "../assets/shreya_chaudhry/8.jpg"







export const coverImages = [
   
{path: "/masaba-gupta-puma",img: masabaPuma},{path: "/anusha-dandekar-loreal-look1",img:anusha1_4},{path: "/anusha-dandekar-loreal-look2",img: anusha2_5},{path: "/shreya-chaudhry-1",img: shreya1},{path: "/nf-wedding-campaign",img: nfCampaign},{path: "/autumn-winter-2023",img: autumnWinter},{path:"/rsvp-x-nikita", img:rsvp},{path:"/gajragang-x-rishivibhiti", img:ggxrv} ,{path:"/partywear-campaign", img:party} ,{path:"/nykd", img:nykd} , {path:"/so-fishy", img:fishy},{path: "/elegante", img:ele},{path: "/live-linen", img:linen},{path: "/gautam-singh-vig", img:gvig},{path: "/timeless-classics", img:timeless},{path: "/jissa-look-1", img:jl1},{path: "/jissa-look-2", img:jl2},{path: "/jissa-look-3", img:jl3},{path: "/kow", img:kow},{path: "/ashi", img:ashi},{path: "/ceilings", img:jb},{path: "/desi-house-party", img:dhp},{path: "/alexander-wang",img: aw},{path: "/vinaash", img:bg},{path: "/power-dressing", img:pdress},{path: "/retro", img:retro},
]


export const awImages = [aw, aw1,aw2,aw3,aw4,aw5]

export const awDesc = "Taking inspiration from the designer Alexander wang by incorporating his most famous color, black and leather fabric to give the gothic and grunge feel."

export const ashiImages = [ashi, a1,a2,a3,a4,a5,a6]
export const ashiDesc  = "Personal Styling for Ashi Khanna, content creator and influencer on Instagram."

export const bgImages = [bg,bg1,bg2,bg3,bg4,bg5]
export const bgDesc = "Embark on a captivating journey through history with our photoshoot inspired by the legendary Rani Ratnavati and the haunted tale of Bhangarh Fort. From ornate traditional attire to intricate henna designs and mystical props, let us capture the enigmatic allure of Rani Ratnavati's life and the haunting secrets of Bhangarh."

export const jBeachImages = [jb, jbe1, jbe2, jbe3]
export const jbDesc = "Created a soft, subtle look on the beach for a video for Jissa Paul."

export const jl1Images = [jl1, ja1,ja2,ja3,ja4]
export const jl1Desc = "Recreating Kim Kardashian's Met'23 look for the content creator Jissa Paul."

export const jl2Images = [jl2, jb1,jb2,jb3,jb4]
export const jl2Desc = "Recreating Jennifer Lopez's Met'23 look for the content creator Jissa Paul."

export const jl3Images = [jl3, jc1, jc2, jc3]
export const jl3Desc = "Recreating Gigi Hadid's Met'23 look for the content creator Jissa Paul."

export const kowImages = [kow,k1,k2,k3,k4,k5,k6,k7]
export const kowDesc = "Styling for the brand Kingdom of white for their website, instagram, amazon and catalog."

export const retroImages = [retro, r1,r2,r3,r4]
export const retroDesc = "Transport yourself to the enchanting world of vintage elegance. Embracing timeless beauty and sophistication, the photoshoot creates a dreamy atmosphere adorned with lace, pearls, and soft pastel hues. From the graceful fashion choices to the romantic hairstyles and delicate props, every detail will evoke a sense of nostalgia and charm. Join us as we capture the essence of vintage allure, preserving its allure for generations to come."

export const timelessImages = [timeless, tc1,tc2,tc3,tc4,tc5]
export const timelessDesc = "Aiming to create a unique visual story that celebrates Mumbai's culture and fashion. Showcasing the versatility of denim as a fashion staple and highlight the iconic design of Kaali Peeli taxis."

export const dhPartyImages = [dhp,dhp1,dhp2,dhp3,dhp4]
export const dhPartyDesc = "This photoshoot is inspired from the brand Dohtakkeh."

export const gsinghImages = [gvig,gvig1,gvig2,gvig3,gvig4,gvig5,gvig6]
export const gsinghDesc = "Styled tv actor, celebrity and big boss contestant, Gautam Singh for the cover of Fit Villa magazine."

export const eleganteImages = [ele,ele1,ele2,ele3,ele4,ele5]
export const eleganteDesc = "This is a unique combination of classic beauty with a modern twist. The aim is to create a timeless yet modern feel, with a focus on natural beauty. Capturing the beauty of the 1950s era, with a fresh and contemporary feel taking inspiration from the beauty brand Glossier."

export const pdImages = [pdress,pd1,pd2,pd3,pd4]
export const pdDesc = "In a bold display of confidence, power dressing takes center stage as commanding professionals strike dynamic poses, exuding authority through meticulously tailored ensembles and commanding accessories."

export const linenImages = [linen,linen1,linen2,linen3,linen4,linen5,linen6,linen7]
export const linenDesc = "Did a styling project for the brand Live Linen"

export const fishyImages = [fishy,fishy1,fishy2,fishy3,fishy4,fishy5,fishy6,fishy7,fishy8,fishy9,fishy10]
export const fishyDesc = "Immersed in the vibrant spirit of Mumbai, a captivating photoshoot captures the essence of its people amidst the bustling backdrop of Sassoon Dock, showcasing their colorful attire, diverse cultures, and indomitable zest for life."

export const ggxrvImages = [gg1,gg2,gg3,gg4,gg5,gg6,gg7,gg8,gg9,gg10,gg11,gg12]
export const ggxrvDesc = "Capturing the essence of vivacity, the Gajra Gang x Rishi and Vibhuti campaign brought to life a kaleidoscope of colors and energy. The collaboration showcased a collection that is not just bright, vibrant, and loud but a celebration of bold fashion choices. The styling impeccably reflected the dynamic spirit of the Gajra Gang brand, creating a visual feast of exuberance."

export const partyImages = [pc1, pc2, pc3, pc4, pc5, pc6, pc7, pc8, pc9, pc10]
export const partyDesc = "Infusing glamour with a playful spirit, the partywear campaign photoshoot was a dazzling spectacle of fun and excitement, where every ensemble sparkled with opulent bling. The styling effortlessly brought out the vivacious charm, making it a celebration of style and festivity."

export const rsvpImages = [rsvp1, rsvp2, rsvp3, rsvp4, rsvp5, rsvp7, rsvp8, rsvp9, rsvp10, rsvp11, rsvp12, rsvp13, rsvp14, rsvp15, rsvp16];
export const rsvpDesc = "In a captivating collaboration between RSVP and designer Nikita Mhaisalkar, the photoshoot exudes an aura of deep sophistication and bold elegance. With a palette of dark allure, each frame unveils a collection that seamlessly merges avant-garde design with an enigmatic, timeless charm."
export const rsvpLandscapeImages = [14]

export const nykdImages = [nykd1, nykd2, nykd3, nykd4, nykd5, nykd6, nykd7, nykd8, nykd9, nykd10, nykd11, nykd12, nykd13, nykd14];
export const nykdDesc = "Immersed in the cozy world of NYKd's Loungewear Collection, our photoshoot effortlessly captures the essence of comfort and style. With a delightful blend of cute and relaxed vibes, every image resonates the brand's commitment to effortless charm and relaxation."

export const anusha1Images = [anusha1_1,anusha1_2,anusha1_3,anusha1_4,anusha1_5,anusha1_6,anusha1_7]
export const anusha1Desc = "Styled Anusha Dandekar for L’oreal Professionel Indian Hair Dressing Awards"
export const anusha1LandscapeImages = [2]

export const anusha2Images = [anusha2_2,anusha2_3,anusha2_4,anusha2_5,anusha2_6,anusha2_7,anusha2_8]
export const anusha2Desc = "Styled Anusha Dandekar for L’oreal Professionel Indian Hair Dressing Awards"
export const anusha2LandscapeImages = [2]

export const autumnWinterImages = [autumnWinter1,autumnWinter2,autumnWinter3,autumnWinter4,autumnWinter5]
export const autumnWinterDesc = "Styled the Autumn Winter 2023 campaign for Nykaa Fashion"

export const masabaPumaImages = [masabaPuma1,masabaPuma2,masabaPuma3,masabaPuma4,masabaPuma5]
export const masabaPumaDesc = "Work for Masaba Gupta where I styled her for Puma Awareness Campiagn for Women’s IPL"

export const nfImages = [nf1,nf2,nf3,nf4,nf5,nf6,nf7,nf8,nf9,nf10,nf11]
export const nfLandscapeImages = [4,7,10]

export const shreya1Images = [shreya1_1,shreya1_2,shreya1_3,shreya1_4,shreya1_5,shreya1_6,shreya1_7,shreya1_8]
export const shreyaDesc = "Styled Shreya Chaudhry for the event launch of Bandish Bandits season 2 at Amazon Prime Slaet Event"