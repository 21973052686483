import React from 'react'
import resume from "../documents/resume.pdf"


const Resume = () => {
  return (
    <div className='h-screen'>
       <object data={resume} type="application/pdf" width="100%" height="100%">
      <p><a href={resume}>Click this link to view my Resume</a></p>
  </object>
    </div>
  )
}

export default Resume