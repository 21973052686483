import React from 'react';
import { useCallback, useEffect } from 'react';
import trackPathForAnalytics from './trackPathAnalytics';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import { Routes, Route, useLocation } from "react-router-dom"
import Home from './pages/home';
import About from './pages/about';
import ProjectPage from './components/ProjectPage';
import { ashiDesc, ashiImages, awDesc, awImages, bgDesc, bgImages, jBeachImages, jbDesc, jl1Desc, jl1Images, jl2Desc, jl2Images, kowDesc, kowImages, retroDesc, retroImages, jl3Images, jl3Desc, timelessImages, timelessDesc, dhPartyImages, dhPartyDesc, gsinghImages, gsinghDesc, eleganteImages, eleganteDesc, pdImages, pdDesc, linenImages, linenDesc, fishyImages, fishyDesc, ggxrvImages, ggxrvDesc, partyImages, partyDesc, rsvpImages, rsvpDesc, nykdImages, nykdDesc, rsvpLandscapeImages, anusha1Images, anusha1Desc, anusha2Images, anusha2Desc, autumnWinterImages, autumnWinterDesc, masabaPumaImages, masabaPumaDesc, nfImages, shreya1Images, shreyaDesc, anusha1LandscapeImages, anusha2LandscapeImages, nfLandscapeImages } from './Utils/utilsHelper';
import Resume from './pages/resume';


function App() {

  const { pathname, search } = useLocation();

    const analytics = useCallback(() => {
        trackPathForAnalytics({ path: pathname, search: search, title: pathname.split("/")[1] });
    }, [pathname, search]);
	
    useEffect(() => {
        analytics();
    }, [analytics]);


  return (
    <div className="App">
      <Navbar />
      <Routes>
      
      <Route path="/" element={<Home />}/>
      <Route path="about" element={<About />}/>
      <Route path="resume" element={<Resume />}/>
      <Route path="/alexander-wang" element={<ProjectPage 
      title = "Alexander Wang"
      images = {awImages}
      description = {awDesc}
      credits = {
        { photographer: "Mukund Somvanshi",
          mua: "Neha Thapa",
          model: "Sara Mukherjee",
          stylist: "Vibhuti Poddar"
        }
      }
      />}/>
      <Route path="/ashi" element={<ProjectPage 
      title = "Ashi Khanna"
      images = {ashiImages}
      description = {ashiDesc}
     
      />}/>
      <Route path="/vinaash" element={<ProjectPage 
      title = "Vinaash"
      images = {bgImages}
      description = {bgDesc}
      credits = {
        { photographer: "Pratik Chandiramani",
          mua: "Dhwani Arora",
          model: "Maahi Singh",
          stylist: "Vibhuti Poddar"
        }
      }
      />}/>
      <Route path="/ceilings" element={<ProjectPage 
      title = "Ceilings"
      images = {jBeachImages}
      description = {jbDesc}
      credits = {
        { photographer: "Jay Mandavkar",
          model: "Jissa Paul",
          stylist: "Vibhuti Poddar & Parina Shah"
        }
      }
      />}/>
       <Route path="/jissa-look-1" element={<ProjectPage 
      title = "Met Gala - 1"
      images = {jl1Images}
      description = {jl1Desc}
      credits = {
        { photographer: "Jay Mandavkar",
          model: "Jissa Paul",
          stylist: "Vibhuti Poddar & Parina Shah"
        }
      }
      />}/>
      <Route path="/jissa-look-2" element={<ProjectPage 
      title = "Met Gala - 2"
      images = {jl2Images}
      description = {jl2Desc}
      credits = {
        { photographer: "Jay Mandavkar",
        model: "Jissa Paul",
        stylist: "Vibhuti Poddar & Parina Shah"
      }
      }
      />}/>
      <Route path="/jissa-look-3" element={<ProjectPage 
      title = "Met Gala - 3"
      images = {jl3Images}
      description = {jl3Desc}
      credits = {
        { photographer: "Jay Mandavkar",
        model: "Jissa Paul",
        stylist: "Vibhuti Poddar & Parina Shah"
      }
      }
      />}/>
      <Route path="/kow" element={<ProjectPage 
      title = "Kingdom Of White"
      images = {kowImages}
      description = {kowDesc}
     
      />}/>
       <Route path="/retro" element={<ProjectPage 
      title = "A Retro Appeal"
      images = {retroImages}
      description = {retroDesc}
      credits = {
        { photographer: "Diya",
          model: "Kriti Makwana",
          stylist: "Vibhuti Poddar"
        }
      }
      />}/>
      <Route path="/timeless-classics" element={<ProjectPage 
      title = "Timeless Classics"
      images = {timelessImages}
      description = {timelessDesc}
      credits = {
        { photographer: "Pratik Chandiramani",
        mua: "Neha Thapa",
          model: "Pranomi Banerjee",
          stylist: "Vibhuti Poddar"
        }
      }
      />}/>
      <Route path="/desi-house-party" element={<ProjectPage 
      title = "Desi House Party"
      images = {dhPartyImages}
      description = {dhPartyDesc}
      credits = {
        { photographer: "Vibhuti Poddar",
        mua: "Neha Thapa",
          model: "Tushar Khatri, Dhwani Arora & Khushi Sarnot",
          stylist: "Vibhuti Poddar"
        }
      }
      />}/>
      <Route path="/gautam-singh-vig" element={<ProjectPage 
      title = "Gautam Singh Vig"
      images = {gsinghImages}
      description = {gsinghDesc}
     
      />}/>
      <Route path="/elegante" element={<ProjectPage 
      title = "Elegante"
      images = {eleganteImages}
      description = {eleganteDesc}
      credits = {
        { photographer: "Mukund Somvanshi",
        mua: "Neha Thapa",
          model: "Anupriya Asthana, Hetal Bagada & Matti Tariang",
          stylist: "Vibhuti Poddar"
        }
      }
      />}/>
       <Route path="/power-dressing" element={<ProjectPage 
      title = "Power Dressing"
      images = {pdImages}
      description = {pdDesc}
      credits = {
        { photographer: "Diya",
        mua: "Vibhuti Poddar",
          model: "Aditi Jain",
          stylist: "Vibhuti Poddar"
        }
      }
      />}/>
       <Route path="/live-linen" element={<ProjectPage 
      title = "Live Linen - Campaign"
      images = {linenImages}
      description = {linenDesc}
   
      />}/>
      <Route path="/so-fishy" element={<ProjectPage 
      title = "So Fishy"
      images = {fishyImages}
      description = {fishyDesc}
      credits = {
        { photographer: "Raja Verma",
        mua: "Neha Thapa",
          model: "Aakshi Kale",
          stylist: "Vibhuti Poddar"
        }
      }
      />}/>
      <Route path="/gajragang-x-rishivibhiti" element={<ProjectPage 
      title = "Gajra Gang for Rishi Vibhuti"
      images = {ggxrvImages}
      description = {ggxrvDesc}
    
      />}/>

       <Route path="/partywear-campaign" element={<ProjectPage 
      title = "Party Wear Campaign"
      images = {partyImages}
      description = {partyDesc}
    
      />}/>
       <Route path="/rsvp-x-nikita" element={<ProjectPage 
      title = "RSVP x Nikita Mhaisalkar "
      images = {rsvpImages}
      description = {rsvpDesc}
      landscapeImages = {rsvpLandscapeImages}
    
      />}/>

       <Route path="/nykd" element={<ProjectPage 
      title = "NYKD - Loungewear"
      images = {nykdImages}
      description = {nykdDesc}
    
      />}/>

      <Route path="/anusha-dandekar-loreal-look1" element={<ProjectPage 
      title = "Anusha Dandekar (Look 1) "
      images = {anusha1Images}
      description = {anusha1Desc}
      landscapeImages = {anusha1LandscapeImages}
    
      />}/>

      <Route path="/anusha-dandekar-loreal-look2" element={<ProjectPage 
      title = "Anusha Dandekar (Look 2) "
      images = {anusha2Images}
      description = {anusha2Desc}
      landscapeImages = {anusha2LandscapeImages}
    
      />}/>

       <Route path="/autumn-winter-2023" element={<ProjectPage 
      title = "Autumn Winter’23"
      images = {autumnWinterImages}
      description = {autumnWinterDesc}
    
      />}/>

      <Route path="/masaba-gupta-puma" element={<ProjectPage 
      title = "Masaba Gupta Puma"
      images = {masabaPumaImages}
      description = {masabaPumaDesc}
    
      />}/>

       <Route path="/nf-wedding-campaign" element={<ProjectPage 
      title = "Band Baja Baarati Campaign"
      images = {nfImages}
      landscapeImages = {nfLandscapeImages}
    
      />}/>

      <Route path="/shreya-chaudhry-1" element={<ProjectPage 
      title = "Shreya Chaudhry"
      images = {shreya1Images}
      description = {shreyaDesc}
    
      />}/>

      </Routes>
     
    </div>
  );
}

export default App;
