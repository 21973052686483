import React from 'react'
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className='mt-8'>
        <h1 className='text-3xl uppercase text-center font-black'>Vibhuti Poddar</h1>
        <div className='flex flex-row gap-6 justify-center mt-4'>
            <Link to="/">
            <p className='uppercase font-black text-lg'>Home</p>
            
            </Link>

         <Link to="/resume">
         <p className='uppercase font-black text-lg'>Resume</p>
         </Link>
           
            <Link to="about">
            <p className='uppercase font-black text-lg'>About</p>

            </Link>


        </div>
    </div>
  )
}

export default Navbar